import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { isEmpty } from 'lodash'
import Images from '../img/Images'
import Layout from "../components/layout"
import Header from '../components/header'
import queryString from 'query-string'
import DatocmsApi from '../common/datoApi'
import LoadingIcon from '../components/loading-icon'

const ResultComponent = ({content}) => {
	let body = content.highlight && !isEmpty(content.highlight.body) && content.highlight.body[0]
	if(body){
		body = body.replace('[h]', '<em>').replace('[/h]', '</em>').replace('\\\\', '')
	}
	return (
		<div className='result-item'>
			<a href={content.url}>
				<h3>{content.title}</h3>
				<div
					className="content"
					dangerouslySetInnerHTML={{
						__html: body
					}}
				/>
				<span>{content.url.replace(/(^\w+:|^)\/\//, '')}</span>
			</a>
		</div>
	)
}

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
	    	searchText: '',
	    	results: [],
	    	loading: false
	    }
	}

	componentDidMount() {
	  this.searchByKeyword()
	}

	searchByKeyword = () => {
		const values = queryString.parse(this.props.location.search)
		if(values.keyword) {
			this.setState({
		  		searchText: values.keyword
		  	})
		  	this.getDatoResults(values.keyword)
		}
	}

	componentDidUpdate(prevProps) {
	  if (prevProps.location.search !== this.props.location.search) {
	    this.searchByKeyword()
	  }
	}

	getDatoResults = async (keyword) => {
		try {
			this.setState({
		  		loading: true
		  	})
			const res = await DatocmsApi.searchDato(keyword)
            if (res.status === 200) {
            	this.setState({
            		results: res.data.data
            	})
            } else {
                //
            }
        } catch(err) {
            console.log('### createNewemer errored', err);
        }

        this.setState({
	  		loading: false
	  	})
	}

	onChangeText = (e) => {
    	this.setState({searchText: e.target.value})
    }

    handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.handleSearch()
		}
	}

	handleSearch = () => {
		const {searchText} = this.state
		if(!searchText) return
		this.getDatoResults(searchText)
	}

	renderSpinner = () => {
		return (
			<div className='spinner-wrapper'>
				<LoadingIcon />
			</div>
		)
	}

	renderEmptyResults = () => {
		return (
			<p>Unfortunately there were no results.</p>
		)
	}

	render() {
		const { results, loading } = this.state
		const display = results.map((obj, i) => {
			return <ResultComponent content= {obj.attributes} key={i} />
		})

		return (
			<Layout>
	      		<Header />
						<div className="search-page">
	      		<div className='centre-container'>
	      			<div className="inner grid-ten -centre">
							<h2>Search</h2>
	      				<div className='grid-container search-container'>
		      				<div className='grid-half search-block'>
		      					<div className="input-with-icon">
	  								<input
									 	className='search-input'
										value={this.state.searchText}
										type='text'
										name='search'
										placeholder='Search...'
										onChange={this.onChangeText}
										onKeyPress={this.handleKeyPress}
									/>
									<button className="search-icon-wrapper" onClick={this.handleSearch}
									><img className="search-logo" src={Images.searchBlack} alt="logo"/></button>
								</div>
			      			</div>
			      			<div className='grid-half result-container'>
			      				{
			      					loading ?
			      						this.renderSpinner() :
			      						(
			      							isEmpty(display) ?
			      								this.renderEmptyResults()
			      							: display
			      						)

			      				}
			      			</div>
		      			</div>
		      		 </div>
	      		</div>
						</div>
	    	</Layout>
		)
	}
}

export default Search
