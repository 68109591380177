import apisauce from 'apisauce'


const baseURL = 'https://site-api.datocms.com/search-results'

const headers = {
	Authorization: 'API-Token ' + process.env.GATSBY_DATO_SEARCH_TOKEN,
	Accept: 'application/json'
}

const apis = apisauce.create({
	baseURL,
	headers,
	timeout: 15000
})

export default class DatocmsApi {

	static async searchDato(keyword, environment = 'Production', limit = 30 ) {
		try {
			// apis.setHeader('Authorization', token);
			return await apis.get(`?q=${keyword}&environment=${environment}&limit=${limit}`)
		} catch(err) {
			console.log(err);
			return err;
		}
	}
}